window.addEventListener('DOMContentLoaded', () => {
  let allowAnalytics = true

  const initGtag = () => {
    const { analytics } = JSON.parse(window.localStorage.getItem('gdpr') || '{}')
    if (analytics) {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () { dataLayer.push(arguments); }
      window.gtag('js', new Date());
      window.gtag('config', window.ga_tracker)
    }
  }

  const openGdprModal = () => modal.classList.remove('d-none')

  document.getElementById('open-gdpr-config').addEventListener('click', openGdprModal)
  const modal = document.getElementById('gdpr-modal')
  modal.querySelector('.analytics-check').addEventListener('change', e => allowAnalytics = e.target.checked)
  modal.querySelector('.gdpr-accept').addEventListener('click', () => {
    window.localStorage.setItem('gdpr', JSON.stringify({ analytics: allowAnalytics }))
    initGtag()
    modal.classList.add('d-none')
  })

  if (!window.localStorage.getItem('gdpr')) openGdprModal()
  initGtag()
})
